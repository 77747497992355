exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-projetos-portfolio-js": () => import("./../../../src/pages/projetos/portfolio.js" /* webpackChunkName: "component---src-pages-projetos-portfolio-js" */),
  "component---src-pages-projetos-venda-js": () => import("./../../../src/pages/projetos/venda.js" /* webpackChunkName: "component---src-pages-projetos-venda-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-projects-for-sale-js": () => import("./../../../src/templates/projects-for-sale.js" /* webpackChunkName: "component---src-templates-projects-for-sale-js" */),
  "component---src-templates-projects-portfolio-js": () => import("./../../../src/templates/projects-portfolio.js" /* webpackChunkName: "component---src-templates-projects-portfolio-js" */)
}

