module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/ponto-urbano-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b180f6f122c71998f80768ea0a1ce3be"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","pt"],"defaultLanguage":"pt","siteUrl":"https://pontourbano.pt","generateDefaultLanguagePage":false,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-translate-urls/gatsby-browser.js'),
      options: {"plugins":[],"translations":{"en":{"urls.index":"/","urls.projetos":"projects","urls.projects":"projects","urls.venda":"for-sale","urls.for-sale":"for-sale","urls.portfolio":"portfolio","urls.sobre-nos":"about","urls.noticias":"news","urls.contactos":"contacts","urls.apartamentos":"apartments","urls.moradias":"houses","urls.espacos-comerciais":"commercial-properties"},"pt":{"urls.index":"/","urls.projetos":"projetos","urls.projects":"projetos","urls.venda":"venda","urls.for-sale":"venda","urls.portfolio":"portfolio","urls.sobre-nos":"sobre-nos","urls.noticias":"noticias","urls.news":"noticias","urls.contactos":"contactos","urls.apartamentos":"apartamentos","urls.moradias":"moradias","urls.espacos-comerciais":"espacos-comerciais"}},"defaultLocale":"pt","prefix":"urls."},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://noticias.pontourbano.pt/graphql","type":{"__all":{"limit":150},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"mobile":"(max-width: 990px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
